<template>
	<div class="container">
		<top-header></top-header>
		<div class="message width-1300">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/index'}">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/user'}">个人中心</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/messageList'}">留言列表</el-breadcrumb-item>
				<el-breadcrumb-item>我要留言</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="label">个人中心</div>
			<div class="user_center">
				<div class="top-option">
					<div class="item" @click="gourl('/user')">
						<span>基本设置</span>
					</div>
					<!-- <div class="item" @click="gourl('/cloudData')">
						<span>点云数据</span>
					</div> -->
					<div class="item active" @click="gourl('/messageList')">
						<span>留言</span>
					</div>
				</div>
				<div class="mo-label">留言</div>
				<div class="info">
					<div class="title">
						<p>留言标题</p>
						<input type="text" v-model="messageFrom.title" placeholder="请输入标题">
					</div>
					<div class="test">
						<p>留言正文</p>
						<textarea placeholder="请输入您的宝贵问题(15-200字内)" maxlength="200"
							v-model="messageFrom.message"></textarea>
					</div>
					<div class="type">
						<p>联系方式（默认微信）</p>
						<el-select v-model="messageFrom.type" placeholder="请选择">
							<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="phone">
						<p>联系号码</p>
						<input type="text" v-model="messageFrom.phone" placeholder="请输入联系号码">
					</div>
					<div class="bottom-btn">
						<el-button type="primary" @click="messageSubmit">提交留言</el-button>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				messageFrom: {
					title: '',
					message: '',
					phone: '',
					type: 1
				},
				options: [{
						id: 1,
						name: '微信'
					},
					{
						id: 2,
						name: '手机号'
					},
					{
						id: 3,
						name: '邮箱'
					}
				]
			}
		},
		created() {

		},
		methods: {
			messageSubmit() {
				if (this.messageFrom.title == '') {
					this.$message({
						message: '请输入留言标题',
						type: 'error'
					});
					return false
				}
				if (this.messageFrom.message == '') {
					this.$message({
						message: '请输入留言内容',
						type: 'error'
					});
					return false;
				}
				if (this.messageFrom.type == 1 && this.messageFrom.phone == '') {
					this.$message({
						message: '请输入微信号',
						type: 'error'
					});
					return false;
				}
				if (this.messageFrom.type == 2 && this.messageFrom.phone == '') {
					this.$message({
						message: '请输入手机号码',
						type: 'error'
					});
					return false;
				}
				if (this.messageFrom.type == 2 && !(/^[1][3-9][0-9]{9}$/.test(this.messageFrom.phone))) {
					this.$message({
						message: '手机号码格式有误',
						type: 'error'
					});
					return false;
				}
				if (this.messageFrom.type == 3 && this.messageFrom.phone == '') {
					this.$message({
						message: '请输入邮箱',
						type: 'error'
					});
					return false;
				}
				this.$post('add/comment', {
					content: this.messageFrom.message,
					link_number: this.messageFrom.phone,
					title: this.messageFrom.title,
					link_style: this.messageFrom.type
				}).then(res => {
					if (res.data.status == 200) {
						this.$notify({
							title: "提示",
							message: "操作成功！",
							type: "success",
						});
						this.$router.push('/messageList');
					} else {
						this.$notify({
							title: "提示",
							message: res.data.message,
							type: "error",
						});
					}
				});
			},
			gourl(url) {
				this.$router.push(url)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		background-color: #E8E9ED;
	}

	.message {
		margin-top: 60px;
		position: relative;
		padding-top: 20px;
		padding-bottom: 1px;

		.label {
			font-size: 26px;
			letter-spacing: 2px;
			margin: 30px 0;

			@media screen and(min-width:320px) and (max-width:767px) {
				display: none;
			}
		}

		.user_center {
			background-color: #fff;
			margin-bottom: 50px;
			box-sizing: border-box;
			min-height: 70.4vh;
			padding: 20px;
			
			margin: 20px 0;
		}

		.mo-label {
			display: none;

			@media screen and(min-width:320px) and (max-width:767px) {
				display: block;
				margin: 0 10px;
			}
		}

		.bottom-btn {
			margin-top: 40px;
			text-align: center;

			.el-button {
				background: #00337D;
				border-color: #00337D;
			}
		}

		.type {
			font-size: 14px;
			margin-top: 20px;

			p {
				position: relative;
				padding-left: 10px;
				margin-bottom: 15px;

				&::before {
					content: '*';
					color: red;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		.title,
		.test,
		.phone {
			font-size: 14px;
			margin-top: 20px;

			textarea {
				resize: none;
				width: 100%;
				padding: 10px;
				min-height: 200px;
				box-sizing: border-box;
				border: 1px solid #bdbdbd;
				color: #bdbdbd;
				outline: none;
				border-radius: 5px;
				transition: border-color .3s;

				&:focus {
					border-color: #0064C8;
				}
			}

			p {
				position: relative;
				padding-left: 10px;
				margin-bottom: 15px;

				&::before {
					content: '*';
					color: red;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			input {
				width: 100%;
				outline: none;
				height: 40px;
				padding: 0 10px;
				box-sizing: border-box;
				border: 1px solid #bdbdbd;
				color: #bdbdbd;
				border-radius: 5px;
				transition: border-color .3s;
				-moz-appearance: textfield;

				&:focus {
					border-color: #0064C8;
				}
			}

		}

		.info {
			display: inline-block;
			margin-left: 2%;
			width: 75%;
			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
				margin-left: 0;
			}
		}

		.top-option {
			display: inline-block;
			vertical-align: top;
			width: 20%;
			border-right: 1px solid #e5e5e5;
			height: 100%;
			@media screen and (min-width:320px) and (max-width:767px) {
				display: none;
			}
			.item {
				padding: 13px 20px;
				font-size: 14px;
				cursor: pointer;
				transition: background-color .3s;

				&:hover {
					background-color: #E5EAF2;
					color: #00337D;
					border-right: 3px solid #00337D;
				}
			}

			.active {
				background-color: #E5EAF2;
				color: #00337D;
				font-weight: 800;
				border-right: 3px solid #00337D;
			}
		}
	}
</style>
